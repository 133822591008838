import { apiGet, apiPost, filepost } from './service'

export async function listDocTemplate(data) {
	return await apiPost("/api/DocTemplate/list", data);
}

export async function fullListDocTemplate(data) {
	return await apiPost("/api/DocTemplate/fulllist", data);
}

export function getDocTemplate(data) {
	let url = "/api/DocTemplate/read/" + data;
	return apiGet(url, {params: {timestamp: Math.random()}});
}

export function postDocTemplate(data) {
	return filepost("/api/DocTemplate/edit", data);
}

export function addDocTemplate(data) {
	return filepost("/api/DocTemplate/add", data);
}

export function deleteDocTemplate(data) {
	return apiGet("/api/DocTemplate/delete/" + data);
}
